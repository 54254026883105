import React from 'react';

import indexVideo from '../videos/homepage-video.mp4';

const BackgroundVideo = ({ backupImage, headerMain, headerSub, children }) => (
    <section className="background-video">
        <div className="background-video__video-container">
            <video className="background-video__video" autoPlay muted loop>
                <source src={ indexVideo } type="video/mp4" />
                <img className="background-video__image" title="Video content not supported" src={ backupImage } alt="Video content not supported alternate banner displayed. Content: Old man and young girl walking hand in hand" />
            </video>
            <div className="background-video__text-box">
                <h1 className="background-video__header">
                    <span className="background-video__header--main">{ headerMain }</span>
                    <span className="background-video__header--sub">{ headerSub }</span>
                </h1>
            </div>
            <div className="background-video__content">
                { children }
            </div>
        </div>
    </section>
);

export default BackgroundVideo;

import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import chunk from 'lodash/chunk';

import Layout from '../components/layout/layout';
import Section from '../components/layout/section';
import FlexboxItem from '../components/layout/flexbox/flexbox--item';
import FlexboxTwoPart from '../components/layout/flexbox/flexbox--two-part';
import FlexboxThreePart from '../components/layout/flexbox/flexbox--three-part';

import BackgroundVideo from '../components/background-video';
import Button from '../components/button';

import { FixString } from '../functions/FixString';
import { GenerateSEO } from '../functions/GenerateSEO';
import { PhoneNumberFormatter } from '../functions/PhoneNumberFormatter';

import indexImage from '../images/page-images/homepage01.jpg';

const IndexPage = ({ data: { allContentfulGrampusGHomepage: { nodes: homepage } } }) => {
  const section3SubSections = chunk(homepage[0].section3.sections, 3);

  section3SubSections.forEach(section => {
    if (section.length < 3) {
        section.push({
            header: "",
            content: {
                content: ""
            }
        })
    }
  });

  const [phoneNumber, rawPhoneNumber] = PhoneNumberFormatter(homepage[0].phoneNumber);

  return (
    <Layout 
      contactBanner={ true }
      location="home"
      metadata={ GenerateSEO({
        is404: false,
        lang: 'en',
        title: homepage[0].seo.seoTitle,
        description: homepage[0].seo.seoDescription,
        image: homepage[0].Images[0].localFile.childImageSharp.fixed.src,
        imageAlt: homepage[0].Images[0].description,
        url: 'https://www.grampusg.com/'
      })}>
      <BackgroundVideo headerMain={ homepage[0].tagline } headerSub={ homepage[0].subtagline } backupImage={ indexImage }>
        <Button buttonType="main" to="/contact/">{ homepage[0].contactButtonText }</Button>
        <Button buttonType="secondary" to="/about/">{ homepage[0].aboutButtonText }</Button>
      </BackgroundVideo>
      <Section className="first">
        { homepage[0].section1.header ? <h2 className="section__header">{ homepage[0].section1.header }</h2> : '' }
        <FlexboxTwoPart>
          <FlexboxItem>
            {
              homepage[0].section1.sections.map((paragraph, index) => (
                <React.Fragment key={ index }>
                  { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                  { paragraph.content.content ?  <p className="section__paragraph">{ FixString(paragraph.content.content) }</p> : '' }
                </React.Fragment>
              ))
            }
            <a className="homepage__contact-block" href={ `tel:${ rawPhoneNumber }`}>
              <span className="homepage__contact underline">{ homepage[0].contactCallToAction }</span>
              <span className="homepage__contact">{ phoneNumber }</span>
            </a>
          </FlexboxItem>
          <FlexboxItem isImage>
            <Image className="section__image" fluid={ homepage[0].Images[0].localFile.childImageSharp.fluid } alt={ homepage[0].Images[0].description } />
          </FlexboxItem>
        </FlexboxTwoPart>
      </Section>
      <Section className="blue-background">
        { homepage[0].section2.header ? <h2 className="section__header">{ homepage[0].section2.header }</h2> : '' }
        <FlexboxTwoPart>
          <FlexboxItem isImage>
            <h3 className="section__subheader"> Rates as low as $60/Month!</h3>
            <p className="section__paragraph">
                Personalized Car Insurance Quotes For:
            </p>
            <p className="section__paragraph">
                Let Us Help You Get Access To Plans at
            </p>
            <p className="section__paragraph" style={{ marginBottom: "25px" }}>
                <strong>Hassle-Free No Obligation Low Rates</strong>
            </p>
            <Button buttonType="secondary" to="/contact/">Get My Free No Obligation Quote!</Button>
            <ul style={{ listStyleType: "none", marginTop: "25px" }}>
                <li style={{ marginBottom: "15px" }}>Competitive Rates</li>
                <li style={{ marginBottom: "15px" }}>Compare Quotes in Real-Time</li>
                <li style={{ marginBottom: "15px" }}>Access to Plans That Fit Your Budget</li>
            </ul>
          </FlexboxItem>
          <FlexboxItem>
            {
                homepage[0].section2.sections.map((paragraph, index) => (
                <React.Fragment key={ index }>
                    { paragraph.header ? <h3 className="section__subheader">{ paragraph.header }</h3> : '' }
                    { paragraph.content.content ? <p className="section__paragraph">{ FixString(paragraph.content.content) }</p> : '' }
                </React.Fragment>
                ))
            }
          </FlexboxItem>
        </FlexboxTwoPart>
        { homepage[0].section3.header ? <h2 className="section__header">{ homepage[0].section3.header }</h2> : '' }
        {
          section3SubSections.map((itemContainer, index) => (
            <FlexboxThreePart className="add-margin" key={ index }>
              { 
                itemContainer.map((item, index) => (
                  <FlexboxItem key={ index }>
                    { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                    { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p> : '' }
                  </FlexboxItem>
                ))
              }
            </FlexboxThreePart>
          ))
        }
      </Section>
    </Layout>
  );
}

export const query = graphql`
query HomepageQuery {
  allContentfulGrampusGHomepage {
    nodes {
      seo {
        seoTitle
        seoDescription
      }
      Images {
        fixed {
          src
        }
        localFile {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed {
                    src
                }
            }
        }
        description
      }
      tagline
      subtagline
      contactButtonText
      aboutButtonText
      contactCallToAction
      phoneNumber
      section1 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section2 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
      section3 {
        header
        sections {
          header
          content {
            content
          }
        }
      }
    }
  }
}
`;

export default IndexPage;
